import axios from 'axios'
import store from '../store/index'
import {Message,Loading} from "element-ui";

axios.defaults.baseURL='/api'
axios.interceptors.response.use(res =>{
    if(res&&res.status==200){
        if(res&&res.headers&&res.headers.token){
            sessionStorage.setItem('YM_TOKEN',JSON.stringify(res.headers.token))
        }
        if(res&&res.data&&res.data.code==1000){
            return res.data
        }else{
            //根据需求可加特殊处理
            return res.data
        }
    }else{
        return false
    }

},err=>{
    let loadingInstance  = Loading.service({lock:true,spinner:'el-icon-loading',background: `rgba(0, 0, 0, 0.5)`})
    let timeout= setTimeout(()=>{
        loadingInstance.close();
        clearTimeout(timeout)
        timeout=null
    },0)
    if(!window.navigator.onLine){
        return Message.error('网络错误')
    }
    if(err&&err.response){
        if(err&&err.response&&err.response.headers&&err.response.headers.token){
            sessionStorage.setItem('YM_TOKEN',JSON.stringify(err.response.headers.token))
        }
        if(err.response.data&&(err.response.data.status==888||err.response.data.status==999)){
            Message.error('身份过期，请重新登录')
            store.commit('quitLogin')//退出登录
            return err.response?.data
        }
    }
    Message.error('服务器异常')
    return false
})
const request = function ({url,method,params,data,responseType,headers}) {
    let TOKEN
    if(localStorage.getItem('YM_INFO')){
        if(sessionStorage.getItem('YM_TOKEN')||JSON.parse(localStorage.getItem('YM_INFO')).token){
            TOKEN = sessionStorage.getItem('YM_TOKEN')||JSON.parse(localStorage.getItem('YM_INFO')).token
            TOKEN=TOKEN.substring(1,TOKEN.length-1)//去除双引号
        }
    }
    if(TOKEN){
        axios.defaults.headers.common['token']=TOKEN;
    }else{
        delete axios.defaults.headers.common['token'];
    }
    return axios({
        url,
        method,
        params,
        data,
        responseType,
        headers,
        timeout:30000
    })
}

export default request
