import moment from './js/moment';
moment.suppressDeprecationWarnings=true//关闭非RFC2822或ISO格式提示
import areaData from  '../util/json/city.json'
import  * as xlsxJs from "xlsx/dist/xlsx";
import xlsxStyle from "xlsx-style";
import Vue from "vue";
import routerHandle from '@/util/js/routerHandle'
export default {
    /**
     * @param timeStr 时间类数值，如时间戳 支持数组2长度类型
     * @param type 想要转换的时间类型，如'YYYY-MM-DD hh:mm:ss dddd'
     * @returns {*}
     */
    'moment':function(timeStr,type) {
        if(!timeStr)return '--'
        if(timeStr instanceof Array&&timeStr.length==2){
            const data =[timeStr[0],timeStr[1]+23*3600*1000+3599*1000]
            return data.map(item=>moment(item).format(String(type)))
        }
         return  timeStr&&String(timeStr).length?moment(timeStr).format(String(type)):timeStr
    },
    /**
     * 时间类型转换为时间戳，支持字符串或数组数据[时间戳，时间戳]
     * @param data
     * @returns {number[]|number}
     */
    'timeStamp':function(data) {
        if(data instanceof Array&&data.length==2){
            const arr =[data[0],data[1]+24*3599*1000]
            return arr.map(item=>new Date(item).getTime())
        }
        return data&&String(data).length?new Date(data).getTime():data
    },
    /**
     * 时间类型转换为时间戳，支持字符串或数组数据[YYYY-MM-DD HH:mm:ss,YYYY-MM-DD HH:mm:ss]
     * @param data
     * @returns {number[]|number}
     */
    'arrYMDHms':function(data) {
        if(data instanceof Array&&data.length==2){
            const arr =[this.moment(data[0],'YYYY-MM-DD 00:00:00'),this.moment(data[1],'YYYY-MM-DD 23:59:59')]
            return arr
        }
        return ''
    },
    /**
     * 只支持最近两天内的时间转换
     * @param timeStr 时间类数值，如时间戳
     * @returns {{api: {coord: function(*=, *=): *}, coordSys: {cellWidth: *, rangeInfo: {weeks: *, dayCount: *, start: *, end: *}, x: *, width: *, y: *, cellHeight: *, type: string, height: *}}|*}
     */

    'chatmoment':function (timeStr) {
      return moment(timeStr).calendar();
    },

    /**
     * 设置cookie 1、name 2、value 3、time(day)
     * @param cname
     * @param cvalue
     * @param exdays
     */

    'setCookie':function (cname,cvalue,exdays) {
        var d = new Date();
        d.setTime(d.getTime()+(exdays*24*60*60*1000));
        var expires = "expires="+d.toGMTString();
        document.cookie = cname + "=" + cvalue + "; " + expires;
    },

    /**
     * 获取cookie 1、name
     * @param cname
     * @returns {string}
     */

    'getCookie':function (cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for(var i=0; i<ca.length; i++)
        {
            var c = ca[i].trim();
            if (c.indexOf(name)==0) return c.substring(name.length,c.length);
        }
        return "";
    },

    /**
     * 根据类型名和id获取对应name
     * @param type
     * @param data
     * @returns {string|[]}
     */
    'dictSelectName':function (type,data) {
        if(!(data instanceof Array)) {
            data = JSON.parse(data)
            if(!(data instanceof Array)){
                data=[String(data)]
                if(!(data instanceof Array)){
                    return '--'
                }
            }
        }
        const dict = JSON.parse(localStorage.getItem('YM_DICT'))
        let arr = ''
        let name = []
        dict.detail.forEach(item=>{
            if(item.name==type){
                arr=item.detail
            }
        })
        const Result = (SArr,data)=>{
            SArr.forEach(item=>{
                if(data.includes(String(item.id))){
                   name.push(item.name)
                }else if(item.detail&&item.detail.length){
                    Result(item.detail,data)
                }
            })
            return name
        }
        return arr&&Result(arr,data)||'--'
    },

    /**
     * 根据类型名和id获取对应ID
     * @param type
     * @param data
     * @returns {string|[]}
     */
    'dictSelectId':function (type,data) {
        if(!(data instanceof Array)) {
            data = JSON.parse(data)
            if(!(data instanceof Array)){
                return '数据异常'
            }
        }
        const dict = JSON.parse(localStorage.getItem('YM_DICT'))
        let arr = ''
        let id = []
        dict.detail.forEach(item=>{
            if(item.name==type){
                arr=item.detail
            }
        })
        const Result = (SArr,data)=>{
            SArr.forEach(item=>{
                if(data.includes(String(item.id))){
                    id.push(item.id)
                }else if(item.detail&&item.detail.length){
                    Result(item.detail,data)
                }
            })
            return id
        }
        return Result(arr,data)
    },
    /**
     * 传数组 需筛选的数据
     * @param {Object} arr
     * @param {Object} data
     */
    'dictSelectNamePro': function(arr, data) {
        if (!(data instanceof Array)) {
            data = JSON.parse(data)
            if (!(data instanceof Array)) {
                return '数据异常'
            }
        }
        let name = []
        const Result = (SArr, data) => {
            SArr.forEach(item => {
                if (data.includes(String(item.id))) {
                    name.push(item.name)
                } else if (item.detail && item.detail.length) {
                    Result(item.detail, data)
                }
            })
            return name
        }
        return Result(arr, data)
    },
    /**
     * 字典 递归 获取对应id
     * @param name
     * @returns {undefined|string}
     */
    'dictProp':function (name) {
        const dict = JSON.parse(localStorage.getItem('YM_DICT'))
        let id = ''
        const Fun = (data,val)=>{
            if(!val) return
            data.forEach(item=>{
                if(item.name==val){
                     id =  item.id
                }else
                if(item.detail&&item.detail.length){
                    Fun(item.detail,val)
                }
            })
            return id
        }
        return Fun(dict.detail,name)
    },

    /**
     * 字典 递归 获取对应name
     * @param id
     * @returns {undefined|string}
     */

    'dictPropName':function (id) {
        if(!id)return '--'
        const dict = JSON.parse(localStorage.getItem('YM_DICT'))
        let name = ''
        const Fun = (data,val)=>{
            if(!val) return
            data.forEach(item=>{
                if(item.id==val){
                    name =  item.name
                }else
                if(item.detail&&item.detail.length){
                    Fun(item.detail,val)
                }
            })
            return name
        }
        return Fun(dict.detail,id)
    },

    /**
     * 根据公司id获取公司名字
     * @param id
     * @returns {undefined|string}
     */

    'CompanyListPropName':function (id,companyList) {
        companyList = companyList||JSON.parse(localStorage.getItem('gsTotal_companyList'))
        let name = ''
        const Fun = (data,val)=>{
            if(!val) return
            data.forEach(item=>{
                if(item.id==val){
                    name =  item.companyName
                }
            })
            return name
        }
        return Fun(companyList,id)
    },

    /**
     * 根据公司id获取公司地址
     * @param id
     * @returns {undefined|string}
     */

    'CompanyListPropArea':function (id,companyList) {
        companyList = companyList||JSON.parse(localStorage.getItem('gsTotal_companyList'))
        let area = ''
        const Fun = (data,val)=>{
            if(!val) return
            data.forEach(item=>{
                if(item.id==val){
                    area =  item.address
                }
            })
            return area
        }
        return Fun(companyList,id)
    },

    /**
     * 字典 递归 根据名称获取对应detail对象
     * @param name
     * @returns {undefined|[]}
     */

    'dictPropOBJ':function (name) {
        if(!name)return null
        const dict = JSON.parse(localStorage.getItem('YM_DICT'))
        let arr = []
        const Fun = function(data,val){
            if(!val) return
            data.forEach(item=>{
                if(item.name==val){
                  arr = item.detail
                }else
                if(item.detail&&item.detail.length){
                    Fun(item.detail,val)
                }
            })
            return arr
        }
       return Fun(dict.detail,name)
    },
    /**
     * 根据区adcode获取省市区adcode
     * @param adcode
     * @returns {*[]}
     */
    'dictGetCodeArr':function (adcode) {
        let arr = []
        const Fun = function (data,id) {
            if (!id) return
            data.forEach(one => {
                if(one.adcode==id){
                    arr=[one.adcode]
                }
                if(one.children&&one.children.length){
                    one.children.forEach(two=>{
                        if(two.adcode==id){
                            arr=[one.adcode,two.adcode]
                        }
                        if(two.children&&two.children.length){
                            two.children.forEach(three=>{
                                if(three.adcode==id){
                                    arr=[one.adcode,two.adcode,three.adcode]
                                }
                            })
                        }
                    })
                }
            })
            return arr
        }
        return Fun(areaData,adcode)
    },
    /**
     * 根据区adcode 获取省市区
     * @param adcode
     * @returns {[]}
     */

    'dictPropArea':function (adcode) {
        if(!adcode)return '--'
        let arr = []
        const Fun = function (data,id) {
            if (!id) return
            data.forEach(one => {
                if(one.adcode==id){
                    arr=[one.title]
                }
                if(one.children&&one.children.length){
                    one.children.forEach(two=>{
                        if(two.adcode==id){
                            arr=[one.title,two.title]
                        }
                        if(two.children&&two.children.length){
                            two.children.forEach(three=>{
                                if(three.adcode==id){
                                    arr=[one.title,two.title,three.title]
                                }
                            })
                        }
                    })
                }
            })
            return arr
        }
        return Fun(areaData,adcode)
    },

    /**
     * 根据区name 获取省市区adcode
     * @param adName
     * @returns {undefined|[]}
     */

    'dictPropAreaId':function (adName) {
        let arr = []
        const Fun = function (data,name) {
            if (!name) return
            data.forEach(one => {
                if(one.title==name){
                    arr=[one.adcode]
                }
                if(one.children&&one.children.length){
                    one.children.forEach(two=>{
                        if(two.title==name){
                            arr=[one.adcode,two.adcode]
                        }
                        if(two.children&&two.children.length){
                            two.children.forEach(three=>{
                                if(three.title==name){
                                    arr=[one.adcode,two.adcode,three.adcode]
                                }
                            })
                        }
                    })
                }
            })
            return arr
        }
        return Fun(areaData,adName)
    },
    /**
     * element 时间范围组件value值返回修改1 [YYYY-MM-DD 00:00:00,YYYY-MM-DD 23:59:59]
     * @param data
     * @returns {string[]}
     */
    timeValArrHandle(data){
        if(data instanceof Array&&data.length==2){
            return [data[0]+' 00:00:00',data[1]+' 23:59:59']
        }
        return ''
    },
    /**
     * element 时间范围组件value值返回修改2
     * @param data
     * @returns {(*|number)[]}
     */
    timeValStrHandle(data){
        if(data instanceof Array){
            return [data[0],data[1]+3600*1000*24-1000]
        }
        return ''
    },
    /**
     * 配置考勤打卡时间上下班数据项
     * @param data
     * @returns {any}
     */
    clockInTimeHandle(data){
        for (let item=0;item<data.length;item++){
            for (let i =0;i<=23;i++){
                data[item].children.push({value:String(i).length===1?`0${i}时`:`${i}时`,label:String(i).length==1?`0${i}时`:`${i}时`,children:[]})
                for (let t=0;t<=59;t++){
                    data[item].children[i].children.push({value:String(t).length===1?`0${t}分`:`${t}分`,label:String(t).length==1?`0${t}分`:`${t}分`})
                }
            }
        }
        return JSON.parse(JSON.stringify(data))
    },
    /**
     * 表格导出办法
     * @param tableId
     * @param filename
     */
    exportTableToExcel(tableId='ETable', filename = ''){
     return   new Promise(resolve => {
            let downloadLink;
            let dataType = 'application/vnd.ms-excel';
            let tableSelect = document.getElementById(tableId||'ETable');
            let tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');
            // Specify file name
            filename = filename ? filename + '.xls' : 'excel_data.xls';

            // Create download link element
            downloadLink = document.createElement("a");

            document.body.appendChild(downloadLink);

            if(navigator.msSaveOrOpenBlob){
                let blob = new Blob(['\ufeff', tableHTML], {
                    type: dataType
                });
                navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                // Create a link to the file
                downloadLink.href = 'data:' + dataType + ', ' + tableHTML;

                // Setting the file name
                downloadLink.download = filename;

                // Triggering the function
                downloadLink.click();
            }
         resolve()
        })
    },
    //将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
    sheet2blob(sheet, sheetName) {
        sheetName = sheetName || 'sheet1';
        var workbook = {
            SheetNames: [sheetName],
            Sheets: {}
        };
        workbook.Sheets[sheetName] = sheet; // 生成excel的配置项

        var wopts = {
            bookType: 'xlsx', // 要生成的文件类型
            bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
            type: 'binary'
        };
        var wbout = xlsxStyle.write(workbook, wopts);
        var blob = new Blob([s2ab(wbout)], {
            type: "application/octet-stream"
        }); // 字符串转ArrayBuffer
        function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }
        return blob;
    },
    //下载
    openDownloadDialog(url, saveName) {
        if (typeof url == 'object' && url instanceof Blob) {
            url = URL.createObjectURL(url); // 创建blob地址
        }
        var aLink = document.createElement('a');
        aLink.href = url;
        aLink.download = saveName || ''; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
        var event;
        if (window.MouseEvent) event = new MouseEvent('click');
        else {
            event = document.createEvent('MouseEvents');
            event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        }
        aLink.dispatchEvent(event);
    },
    /**
     * xlsx插件导出表格方法
     * @param tableId 表格对象id
     * @param length 列的长度
     * @param name 表格名称
     */
    exportTableExcel(tableId='ETable',length=0,name='工资条'){
        //length ==>导出的总列数
        return new Promise(resolve => {
            /* generate workbook object from table */
            var wb = xlsxJs.utils.table_to_sheet(document.querySelector(`#${tableId?tableId:'ETable'}`),{ raw: true });
            // console.log(wb)
            if(!wb['!ref']){
                this.$message.warning('无法导出：报表无数据');
                return
            }
            wb["!cols"]=[]
            //表格列数长度
            wb["!rows"]=[{hpx: 100,hpt:100}]//设置表头行高没生效
            for(var i = 0;i<length;i++){
                wb["!cols"][i]={wpx:160}
            }
            let operate = null//操作列标记
            // 样式的文档地址
            // https://www.npmjs.com/package/xlsx-style
            for (const key in wb) {
                //存储操作列key值
                if(wb[key].v==='操作'){
                    operate=key.slice(0,1)
                }
                //删除操作列和空列
                if(wb[key].t==='z'||key.slice(0,1)===operate){
                    delete wb[key]
                    continue
                }
                if(key.indexOf('!') === -1&&wb[key].v){
                    wb[key].s ={
                        font:{//字体设置
                            sz:13,
                            bold:false,
                            color:{
                                rgb:'000000'//十六进制，不带#
                            }
                        },
                        alignment:{//文字居中
                            horizontal:'center',
                            vertical:'center',
                            wrap_text:true
                        },
                        border: { // 设置边框
                            top: { style: 'thin' },
                            bottom: { style: 'thin' },
                            left: { style: 'thin' },
                            right: { style: 'thin' }
                        },
                    }
                    //表头样式设置
                    if(key.indexOf('!') === -1&&key.slice(1)==='1'){
                        wb[key].s={
                            font:{//字体设置
                                sz:13,
                                bold:false,
                                color:{
                                    rgb:'000000'//十六进制，不带#
                                }
                            },
                            alignment:{//文字居中
                                horizontal:'center',
                                vertical:'center',
                                wrap_text:true
                            },
                            border: { // 设置边框
                                top: { style: 'thin' },
                                bottom: { style: 'thin' },
                                left: { style: 'thin' },
                                right: { style: 'thin' }
                            },
                            fill: { // 设置边框
                                bgColor:{
                                    rgb:'606266'//十六进制，不带#
                                },
                                fgColor:{
                                    rgb:"e6e6e6"
                                }
                            }
                        }
                    }
                }
            }
            let fileData = this.sheet2blob(wb)
            this.openDownloadDialog(fileData,name + ".xlsx")
            resolve()
        })
    },
    /**
     * 分页切换封装
     * @returns {function(...[*]=)}
     */
    paging(getList){
        return new Promise(resolve => {
            Vue.prototype.$ELoading()
            getList()?.then(()=>{
                resolve()
            })
        })
    },
    /**
     * 传递权限树数据 获取处理过的功能树
     * @param data
     * @returns {undefined|[]}
     */
    getRouter(data){
        return routerHandle(data)
    },
    /**
     * 根据身份证获取年龄
     * @param idCard
     * @returns {number}
     */
    getAgeFromIdCard(idCard) {
        if(!idCard)return '--'
        var birthYear = idCard.substring(6, 10);
        var birthMonth = idCard.substring(10, 12);
        var birthDay = idCard.substring(12, 14);

        var currentDate = new Date();
        var currentYear = currentDate.getFullYear();
        var currentMonth = currentDate.getMonth() + 1;
        var currentDay = currentDate.getDate();

        var age = currentYear - birthYear;

        if (currentMonth < birthMonth || (currentMonth == birthMonth && currentDay < birthDay)) {
            age--;
        }

        return age;
    },
    /**
     * 获取当前月第一天和最后一天的值
     * @returns {{firstDay: Date, lastDay: Date}}
     */
    getNowMonthDay() {
        const today = new Date();
        const firstDay = this.moment(new Date(today.getFullYear(), today.getMonth(), 1),'YYYY-MM-DD');
        const lastDay = this.moment(new Date(today.getFullYear(), today.getMonth() + 1, 0),'YYYY-MM-DD');
        return { firstDay, lastDay };
    }

}
