import routerJson from "@/router/index.json"
const dataJsonHandle= function(data){
    let dataJson =data instanceof Array?data:JSON.parse(data)
    if(!(dataJson&&dataJson.length))return
    //如果没有项目管理，能查看项目管理，但是不能创建项目
    let projectItem = null
    //如果没有日结考勤，能看到基础功能，无法创建考勤组和结算模式
    let dayClockItem = null
    routerJson.forEach(item=>{
        if(item.name==='光速发薪'){
            projectItem =  item.children?.filter(itemSon=>itemSon.path==='/payroll/project')[0]
            projectItem.meta.addBtn=false//没有创建项目按钮
            dayClockItem = item.children?.filter(itemSon=>itemSon.path==='/payroll/clockIn')[0]
            dayClockItem.meta.addBtn=false//没有创建考勤组和结算模式的按钮
        }
    })
    dataJson.forEach(item=>{
        if(item.name==='光速发薪'){
            let hasProject = false
            let hasDayClock = false
            item.children.forEach(itemSon=>{
                if(itemSon.path==='/payroll/project') hasProject=true
                if(itemSon.path==='/payroll/clockIn') hasDayClock=true
            })
            if(!hasProject)item.children.push(projectItem)
            if(!hasDayClock)item.children.push(dayClockItem)
        }
    })
    let arr = []
    if(dataJson.length){
        dataJson.forEach(item=>{
            let a = {
                id:item.id,
                upid:item.upid,
                path:item.path,
                name: item.name,
                component:resolve => require(['@/views'+item.component], resolve),
                meta:item.meta?item.meta instanceof Object?item.meta:JSON.parse(item.meta):{},
                children:[]
            }
            if(item.children&&item.children.length){
                if(item.name==='光速发薪'){
                    a.redirect=item.children.filter(fItem=>fItem.path==='/payroll/home')[0]?.path||item.children[0].path
                }else if(item.name==='易用众包商家'){
                    a.redirect=item.children.filter(fItem=>fItem.path==='/package/home')[0]?.path||item.children[0].path
                }else{
                    a.redirect=item.children&&item.children.length?item.children[0].path:''
                }
            }
            arr.push(a)
        })
        dataJson.forEach((item,i)=>{
            //光速发薪有财务审批模块时，让OA审批一直保持有查看的权限
            if(item.name==='光速发薪'){
                item.children&&item.children?.forEach((vitem,vi)=>{
                    if(vitem.name==='财务审批'){
                        const temp =  vitem.children&&vitem.children?.filter(witem=>witem.name==='OA审批')
                        //如果没有OA审批则默认只支持查看页面
                        if(!(temp&&temp.length)){
                            vitem.children.push({
                                "path": "/payroll/financeApproval/payrollApproval",
                                "name": "OA审批",
                                "component": "/routerExport",
                                "meta": {
                                    "icon":"el-icon-menu",
                                    "title": "优米前程-OA审批",
                                    "name": "OA审批",
                                    "redirect":"/payroll/financeApproval/payrollApproval/default",
                                    "btn":[]
                                },
                                "children": [
                                    {
                                        "path": "/payroll/financeApproval/payrollApproval/default",
                                        "name": "OA审批-操作",
                                        "component": "/payroll/financeApproval/payrollApproval/payrollApproval",
                                        "meta": {
                                            "faRouter": "/payroll/financeApproval/payrollApproval",
                                            "icon":"el-icon-menu",
                                            "title": "优米前程-OA审批",
                                            "name": "OA审批",
                                            "keepAlive": true,
                                            "btn":[]
                                        }
                                    },
                                    {
                                        "path": "/payroll/financeApproval/payrollApproval/detail",
                                        "name": "OA审批-详情",
                                        "component": "/payroll/financeApproval/payrollApproval/detail",
                                        "meta": {
                                            "faRouter": "/payroll/financeApproval/payrollApproval",
                                            "icon":"el-icon-menu",
                                            "title": "优米前程-OA审批",
                                            "name": "OA审批",
                                            "btn":[]
                                        }
                                    }
                                ]
                            })
                        }
                    }
                })
            }
            if(item.children&&item.children.length){
                item.children.forEach(vitem=>{
                    let a = {
                        id:vitem.id,
                        upid:vitem.upid,
                        path:vitem.path,
                        name: vitem.name,
                        component:resolve => require(['@/views'+vitem.component], resolve),
                        meta:vitem.meta?vitem.meta instanceof Object?vitem.meta:JSON.parse(vitem.meta):{},
                        children:[]
                    }
                    if(vitem.children&&vitem.children.length){
                        if(vitem.meta instanceof Object?vitem.meta.redirect:JSON.parse(vitem.meta).redirect.includes('default')){
                            a.redirect=vitem.meta instanceof Object?vitem.meta.redirect:JSON.parse(vitem.meta).redirect
                        }else{
                            a.redirect=vitem.children&&vitem.children.length?vitem.children[0].path:''
                        }
                    }
                    arr[i].children.push(a)
                })
            }
        })
        dataJson.forEach((item,i)=>{
            if(item.children&&item.children.length){
                item.children.forEach((vitem,vi)=>{
                    if(vitem.children&&vitem.children.length){
                        vitem.children.forEach(witem=>{
                            let a = {
                                id:witem.id,
                                upid:witem.upid,
                                path:witem.path,
                                name: witem.name,
                                component:resolve => require(['@/views'+witem.component], resolve),
                                meta:witem.meta?(witem.meta instanceof Object?witem.meta:JSON.parse(witem.meta)):{},
                                children:[]
                            }
                            if(witem.meta instanceof Object?witem.meta.redirect:JSON.parse(witem.meta).redirect){
                                a.redirect=witem.meta instanceof Object?witem.meta.redirect:JSON.parse(witem.meta).redirect
                            }
                            arr[i].children[vi].children.push(a)
                        })
                    }
                })
            }
        })
        dataJson.forEach((item,i)=>{
            if(item.children&&item.children.length){
                item.children.forEach((vitem,vi)=>{
                    if(vitem.children&&vitem.children.length){
                        vitem.children.forEach((witem,wi)=>{
                            if(witem.children&&witem.children.length){
                                witem.children.forEach((xitem,xi)=>{
                                    let a
                                    if(!xitem.component){
                                    }else{
                                        a = {
                                            id:xitem.id,
                                            upid:xitem.upid,
                                            path:xitem&&xitem.path||'',
                                            name: xitem.name,
                                            component:(resolve => require(['@/views'+xitem.component], resolve))||'',
                                            meta:xitem.meta?xitem.meta instanceof Object?xitem.meta:JSON.parse(xitem.meta):{},
                                            children:[],
                                        }
                                        arr[i].children[vi].children[wi].children.push(a)
                                    }
                                })
                            }
                        })
                    }
                })
            }
        })
        return arr
    }
}
export default dataJsonHandle
