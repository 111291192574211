<template>
    <div class="login">
        <div class="loginRight">
            <div>
                <img src="../assets/login/logo.png" style="width: 123px;height: 123px;" alt="" v-if="!switchText.registerLogo"/>
                <span v-else style="color: rgba(0, 0, 0, 1);font-size: 32px;font-weight: 400;">短信注册</span>
            </div>
            <el-form :model="user" :rules="userRule" ref="ruleForm">
                <el-form-item label="" prop="mobile" :error="mobileError">
                    <div style="margin-top: 50px" class="inputBox" @keydown.enter="loginBtn">
                        <el-input type="text" placeholder="请输入手机号" autofocus v-model="user.mobile" maxlength="11"/>
                        <div class="titleBox">
                            手机号
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="" prop="authCode" :error="codeError">
                    <div style="margin-top: 35px" class="inputBox" @keydown.enter="loginBtn">
                        <el-input type="text" placeholder="请输入验证码" v-model="user.authCode" maxlength="6">
                            <template #suffix>
                                <div style="display: flex;align-items: center;height: 100%">
                                    <div style="width: 1px;height: 19px;background: rgba(138, 181, 255, 1)"></div>
                                    <div class="getCodeClass" @click="getCodeHandle" v-if="!countdown">获取验证码</div>
                                    <div style="display: flex;align-items: center;width: 60px;justify-content: center;margin-left: 14px"
                                         v-else>
                                        <el-statistic :value="deadline" :time-indices="countdown" format="ss"
                                                      @finish="timeFinish">
                                            <template slot="suffix"> s</template>
                                        </el-statistic>
                                    </div>
                                </div>
                            </template>
                        </el-input>
                        <div class="titleBox">
                            验证码
                        </div>
                    </div>
                </el-form-item>
<!--                <div style="padding-top: 20px;text-align: center">-->
<!--                    <el-radio-group v-model="user.type">-->
<!--                        <el-radio :label="2">用户管理员</el-radio>-->
<!--                        <el-radio :label="1">超级管理员</el-radio>-->
<!--                    </el-radio-group>-->
<!--                </div>-->
            </el-form>
            <div style="text-align: right;margin-top: 20px;font-size: 14px;color: rgb(123,123,123)">
                登录视为您已同意 <span style="color: rgba(36, 138, 255, 1);cursor: pointer" @click="openWindowPage(1)">《优米前程用户协议》</span>
                和 <span style="color: rgba(36, 138, 255, 1);cursor: pointer" @click="openWindowPage(2)">《隐私政策》</span>
            </div>
            <div style="margin-top: 20px" @click="loginBtn" class="btnLogin">
                <button v-if="!login" style="cursor: pointer">{{switchText.btnName}}</button>
                <el-button type="primary" :loading="true" v-else>{{switchText.btnState}}</el-button>
            </div>
        </div>
        <el-dialog
                title=""
                :show-close="false"
                :visible.sync="dialogVisible"
                width="30%">
            <div style="display: flex;flex-direction: column;align-items: center;justify-content: center">
                <div style="color: black;font-width: 900;font-size: 14px">注册成功</div>
                <div style="margin-top: 37px">温馨提示：您可以前往账号中心修改手机号，编辑个人信息等</div>
            </div>
            <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="dialogVisible = false">前往账号中心</el-button>
            <el-button type="" @click="dialogVisible = false">返回</el-button>
         </span>
        </el-dialog>
    </div>
</template>

<script>
import {login, getDict, getCode} from "../api/public";
    import {getFunctionThreeShare} from '@/api/share/publicShareApi'
    import {getUserThree} from '@/api/authority'
    import routerJson from '@/router/index.json'
    export default {
        name: "login",
        data() {
            return {
                login: false,
                codeReq: {
                    userType: 5,
                    mobile: null,
                    type: 1
                },
                user: {
                    mobile: null,
                    authCode: null,
                    type: 2,
                    platFormType:1,
                },
                userRule: {
                    mobile: [
                        {required: true, message: '请输入手机号', trigger: 'change'},
                        {pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/, message: '请输入有效手机号', trigger: 'blur'},
                    ],
                    authCode: [
                        {required: true, message: '请输入验证码', trigger: 'change'},
                    ]
                },
                mobileError: '',//手机号验证提示
                codeError: '',//验证码验证提示
                savePass: false,//是否记住密码 ture是 false否
                checked: false,//登录协议
                countdown: false,//倒计时开关
                deadline: 0,
                switchText: {
                    btnName: '登录',
                    btnState: '登录中',
                    topRightTag: '注册',
                    registerLogo: false,
                },
                dialogVisible: false,//控制弹窗
            }
        },
        created() {},
        methods: {
            // 打开协议页面
            openWindowPage(v) {
                const page = window.open()
                if (v == 1) {
                    page.document.write('用户协议')
                } else {
                    page.document.write('隐私协议')
                }
            },
            //点击获取验证码
            getCodeHandle() {
                this.codeReq.mobile = this.user.mobile
                if (!this.codeReq.mobile) return this.mobileError = '手机号不能为空'
                this.countdown = true
                this.deadline = new Date().getTime() + 1000 * 30;//倒计时时长
                getCode(this.codeReq).then(res => {
                  if(res){
                    if (res.code == 1000) {
                      this.$message.success('发送成功')
                    } else {
                      this.countdown = false
                      this.$message.error('发送失败')
                    }
                  }
                })

            },
            //倒计时结束后触发
            timeFinish() {
                this.countdown = false
            },
            //登录、注册切换
            switchHandle() {
                this.checked = false
                //点击注册 表单重置
                this.$refs['ruleForm'].resetFields()
                if (!this.switchText.registerLogo) {
                    this.switchText = {
                        btnName: '注册',
                        btnState: '注册中',
                        topRightTag: '登录',
                        registerLogo: true,
                    }
                } else {
                    this.switchText = {
                        btnName: '登录',
                        btnState: '登录中',
                        topRightTag: '注册',
                        registerLogo: false,
                    }
                }

            },
            loginBtn() {
                if (this.switchText.btnName == '注册') {
                    this.dialogVisible = true
                    return
                }
                if (this.login) {
                    return
                }
                this.login = true
                this.mobileError = ''//错误信息提示清空
                this.codeError = ''//错误信息提示清空
                this.$refs['ruleForm'].validate((valid) => {
                    if (valid) {
                        //通过校验执行登录函数
                        this.gotoSystem()
                    } else {
                        this.login = false
                        return false;
                    }
                })
            },
            gotoSystem() {
                return new Promise(resolve => {
                    this.$store.commit('quitLogin')
                    login(this.$req(this.user)).then(res => {
                        if(res.code){
                            if (res.code == 1000) {
                                if (!res.data) return
                                localStorage.setItem('YM_INFO', JSON.stringify(res.data))
                                sessionStorage.setItem('YM_TOKEN', JSON.stringify(res.data.token))
                                this.getDict().then(() => {
                                    //获取功能树
                                    this.getFunctionThree().then((response) => {
                                        if (res.data.type === "1") {
                                            if (response.code == 1000) {
                                              this.switchLocal(response.data)
                                            }else{
                                              this.$message.error(res.message||'操作失败')
                                            }
                                            //直接路由跳转
                                            this.routerGOto()
                                        } else {
                                            this.switchLocal(res.data.auth)
                                            this.routerGOto()
                                        }
                                    })
                                }).catch(() => {
                                    this.login = false;
                                })
                            }else {
                                if(res.code==='ERR_NETWORK'){
                                    this.codeError = '网络错误'
                                    this.login = false;
                                    return
                                }
                                this.codeError = res.message ? res.message : '手机号或验证码错误'
                                this.login = false;
                            }
                        }else {
                            this.login = false;
                        }
                    }).catch(() => {
                        resolve()
                        this.login = false;
                    })
                })

            },
          //网路存储/本地存储
          switchLocal(data,type){
            // this.$store.commit(type==='all'?'allRouterHandle':'userRouterHandle', routerJson)//本地路由
            // localStorage.setItem(type==='all'?'YM_THREE':'YM_USER_THREE', JSON.stringify(routerJson)) //本地路由
            this.$store.commit(type==='all'?'allRouterHandle':'userRouterHandle', data ? data : [])
            localStorage.setItem(type==='all'?'YM_THREE':'YM_USER_THREE', JSON.stringify(data)) //保存账户当前权限
          },
            //路由跳转处理
            routerGOto() {
                if (this.$store.state.beforeRouter && this.$store.state.beforeRouter != '/') {
                    this.login = false;
                    //检测路由权限是否有该路由
                    let flag = false
                    this.$router.getRoutes().forEach(item => {
                        if (item.path == this.$store.state.beforeRouter) {
                            flag = true
                        }
                    })
                    if (flag) {
                        if (this.$store.state.beforeRouter != '/login') {
                          if(this.$store.state.beforeRouter==='/used/team/member'){
                            this.$router.replace('/')
                          }else{
                            // this.$router.replace(this.$store.state.beforeRouter)
                            this.$router.replace('/')
                          }
                            let timeout = setTimeout(() => {
                                this.login = false;
                                clearTimeout(timeout)
                            }, 1000)
                            return
                        }
                    }
                    if (this.$router.getRoutes().length&&this.$router.getRoutes()[2].path) {
                        if(this.$router.getRoutes()[2].path=='/login'){
                            return this.$router.replace('/')
                        }
                        // this.$router.replace(this.$router.getRoutes()[2].path)
                      this.$router.replace('/')
                    } else {
                        this.$router.replace('/')
                    }
                    let timeout = setTimeout(() => {
                        this.login = false;
                        clearTimeout(timeout)
                    }, 1000)
                } else {
                    this.$router.replace('/')
                    let timeout = setTimeout(() => {
                        this.login = false;
                        clearTimeout(timeout)
                    }, 1000)
                }
            },
            //获取字典
            getDict() {
                return new Promise(resolve => {
                    resolve()
                    getDict().then(res => {
                        resolve()
                        if (res.code == 1000) {
                            localStorage.setItem('YM_DICT', res.data)
                        }
                    })
                })
            },
            //获取所有功能树
            getFunctionThree() {
                return new Promise(resolve => {
                    getFunctionThreeShare().then(res => {
                        resolve(res)
                        if (res.code == 1000) {
                          this.switchLocal(res.data,'all')
                        } else {
                            res.message ? this.$message.error(res.message) : this.$message.error('功能获取异常')
                        }
                    })
                })
            },
            //获取用户权限树
            getUserThree(userId) {
                return new Promise(resolve => {
                    getUserThree(userId).then(res => {
                        resolve(res)
                        if (res.code == 1000) {
                            this.$store.commit('userRouterHandle', res.data ? res.data : [])
                            localStorage.setItem('YM_USER_THREE', JSON.stringify(res.data)) //保存账户当前权限
                        }
                    })
                })
            },
        }
    };
</script>

<style scoped>
    .login {
        width: 100vw;
        height: 100vh;
        background: url("../assets/login/left.png"), url("../assets/login/right.png");
        background-position: left, right;
        background-repeat: no-repeat;
        background-size: 58% 100vh, 100% 100vh;
    }

    .loginLeft {
        width: 178px;
        height: 101px;
        position: absolute;
        left: 84px;
        top: 55px;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
    }

    @media screen and (min-width: 1440px) {
        .loginRight {
            width: 30vw;
            height: 70%;
            min-height: 550px;
            opacity: 1;
            border-radius: 11px;
            background: rgba(255, 255, 255, 1);
            box-shadow: -6px 0px 12px rgba(56, 103, 255, 0.58);
            position: absolute;
            top: 50%;
            right: 6vw;
            transform: translateY(-50%);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            transition: all linear .3s;
        }
    }

    @media screen  and (max-width: 1440px) {
        .loginRight {
            width: 35vw;
            height: 70%;
            min-width: 400px;
            min-height: 550px;
            opacity: 1;
            border-radius: 11px;
            background: rgba(255, 255, 255, 1);
            box-shadow: -6px 0px 12px rgba(56, 103, 255, 0.58);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            transition: all linear .3s;
        }
    }

    .loginL1 {
        opacity: 1;
        font-size: 36px;
        font-weight: 900;
        color: rgba(148, 159, 205, 1);
        text-align: left;
    }

    .loginL2 {
        opacity: 1;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 2px;
        color: rgba(148, 159, 205, 1);
        text-align: left;
        vertical-align: top;
        font-size: 16px;
    }

    .loginL3 {
        display: flex;
        width: 100%;
    }

    .loginL3 > div {
        opacity: 1;
        color: rgba(148, 159, 205, 1);
        flex: 1;
        font-size: 18px;
    }

    .loginL3 > div:nth-of-type(1) {
        border-right: 1px solid rgba(148, 159, 205, 1);
    }

    .loginL3 > div:nth-of-type(2) {
        text-align: center;
    }

    .loginL3 > div:nth-of-type(3) {
        border-left: 1px solid rgba(148, 159, 205, 1);
        text-align: right;
    }

    .fontBox {
        width: 490px;
        height: 171px;
        margin-top: 139px;
    }

    .fontBox > div:nth-of-type(1) {
        opacity: 1;
        font-size: 36px;
        font-weight: 900;
        letter-spacing: 0px;
        color: rgba(0, 0, 0, 1);
        text-align: left;
        vertical-align: top;
    }

    .fontBox > div:nth-of-type(2) {
        opacity: 1;
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0px;
        color: rgba(0, 0, 0, 1);
        text-align: left;
        vertical-align: top;
    }

    .fontBox > div:nth-of-type(3) {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 15px;
    }

    .logo {
        width: 319px;
        height: 399px;
        text-align: center;
        margin-top: -195px;
    }

    .login input {
        width: 319px;
        height: 42px;
        opacity: 1;
        border-radius: 6px;
        border: 1.3px solid rgba(56, 103, 255, 1);
        outline: none;
    }

    .btnLogin button {
        width: 319px;
        height: 46px;
        opacity: 1;
        border-radius: 4px;
        background: rgba(42, 85, 229, 1);
        border: 1px solid rgba(42, 85, 229, 1);
        box-shadow: 0px 1px 12px 0px rgba(148, 173, 255, 1);
        color: white;
    }

    .login .el-form-item {
        margin-bottom: 0
    }

    ::v-deep .el-form-item__content .el-form-item__error {
        left: 40px;
    }

    .inputBox {
        position: relative;
    }

    .inputBox > input {
        padding: 0 38px 0 38px;
        box-sizing: border-box;
        font-size: 12px;
        color: rgba(56, 103, 255, 1);
    }

    ::v-deep .inputBox .el-input__suffix {
        right: 20px;
    }

    ::v-deep .inputBox .el-input__icon {
        line-height: 42px;
        font-size: 16px;
    }

    ::v-deep .inputBox .el-input__inner {
        padding: 0 38px 0 38px;
        box-sizing: border-box;
        font-size: 12px;
        color: rgba(56, 103, 255, 1);
        width: 319px;
        height: 42px;
        opacity: 1;
        border-radius: 6px;
        border: 1px solid rgba(56, 103, 255, 1);
        outline: none;
    }

    .titleBox {
        width: 56px;
        height: 22px;
        position: absolute;
        line-height: 22px;
        text-align: center;
        font-size: 14px;
        color: rgba(56, 103, 255, 1);
        top: -11px;
        left: 30px;
        background: white;
    }

    .getCodeClass {
        color: rgba(166, 166, 166, 1);
        font-size: 12px;
        font-width: 500;
        height: 19px;
        line-height: 19px;
        margin-left: 14px;
    }

    .getCodeClass:hover {
        cursor: pointer;
        color: black;
    }

    ::v-deep .el-statistic .head {
        margin: 0;
    }

    ::v-deep .el-statistic .con span {
        line-height: inherit;
        font-size: 14px;
    }

    ::v-deep .el-checkbox__inner {
        border-radius: 50%;
    }

    ::v-deep .el-dialog {
        padding: 10px 30px;
        max-width: 620px;
    }

    ::v-deep .el-dialog__header {
        padding: 0;
    }

    ::v-deep .el-dialog__footer {
        text-align: center;
        margin-top: 80px;
    }

    .el-button + .el-button, .el-checkbox.is-bordered + .el-checkbox.is-bordered {
        margin: 0;
    }

    .dialog-footer > button {
        width: 100%;
        max-width: 434px;
        height: 35px;
    }

    .dialog-footer > button:nth-of-type(1) {
        background: rgba(50, 135, 255, 1);
    }

    .dialog-footer > button:nth-of-type(2) {
        margin-top: 10px;
        border: 1px solid rgba(50, 135, 255, 1);
        color: rgba(50, 135, 255, 1);
    }
</style>
